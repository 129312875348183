import Axios from 'axios';
import React, { useEffect } from 'react';
import { ActivityIndicator } from '../components';

export default function Login() {
  useEffect(() => {
    (async () => {
      const response = await Axios.get('/api/login').catch((/* error */) => {
        window.location.replace('/error');
      });

      window.location.replace(response?.data?.url);
    })();
  }, []);
  return (
    <div>
      <ActivityIndicator />
    </div>
  );
}
